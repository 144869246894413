import {createApp, markRaw, defineAsyncComponent } from "vue";
import '@/mixins/fetch';
import { useLabel } from "./composables/useLabel";

// Main Vue instance
const app = createApp({});

// Async component imports
const components = {
    Cart: () => import("./components/Cart"),
    ProductsSearchRouteWrapper: () => import("./components/Products/ProductsSearchRouteWrapper.vue"),
    Categories: () => import("./components/Category/Categories"),
    ProductCard: () => import("./components/ProductCard"),
    CategoryHome: () => import("./components/Category/CategoryHome"),
    ProductDetail: () => import("./views/ProductDetail"),
    HighlightedBrands: () => import("./components/HighlightedBrands"),
    PopularProducts: () => import("./components/PopularProducts/PopularProducts"),
    Wishlist: () => import("./components/Wishlist"),
    ProductsView: () => import("./components/Products/ProductsView"),
    SearchAutocomplete: () => import("./components/Utility/SearchAutocomplete"),
    WishlistView: () => import("./views/WishlistView"),
    BreadCrumb: () => import("./components/Utility/BreadCrumb"),
    CustomDialog: () => import("./components/Utility/CustomDialog.vue"),
    MenuUser: () => import("./components/MenuUser"),
    RecaptchaField: () => import("./components/Utility/RecaptchaField.vue"),
    CheckoutWrapper: () => import("./layouts/Checkout/CheckoutWrapper"),
    CheckoutSteps: () => import("./layouts/Checkout/CheckoutSteps"),
    CheckoutPayment: () => import("./layouts/Checkout/CheckoutPayment"),
    Datalayers: () => import("./components/Datalayers.vue"),
    CartWrapper: () => import("./layouts/Cart/CartWrapper"),
    DashboardRoot: () => import("./profile/DashboardRoot.vue"),
    QuotationForm: () => import("./views/QuotationForm"),
    ProductsRouteWrapper: () => import("./components/Products/ProductsRouteWrapper.vue"),
};

// import Pinia
import pinia from "./includes/pinia";

// import PrimeVue
import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';

// import VueRouter
import router from "./includes/router/routes";

// import VueReCaptcha
import {VueReCaptcha} from 'vue-recaptcha-v3'

pinia.use(({store}) => {
    store.router = markRaw(router)
});

// Register components with loading and error handling
Object.entries(components).forEach(([name, component]) => {
    app.component(name, defineAsyncComponent({
        loader: component,
        delay: 200,
        errorComponent: {
            // template: '<div class="error-loading">Error loading component</div>'
        },
        loadingComponent: {
            // template: '<div class="loading">Loading...</div>'
        }
    }));
});

useLabel().setMultiple(window.text_elements);

app
    .use(PrimeVue, {
        // unstyled: true
    })
    .use(router)
    .use(pinia)
    .use(VueReCaptcha, {siteKey: process.env.RECAPTCHA_KEY, loaderOptions: {autoHideBadge: true}})
    .directive('tooltip', Tooltip)
    .mount("#app");

    app.config.globalProperties.$filters = {
        toCurrency(value) {
            // value = value.replace(',', '.');
            if (typeof value !== "number") {
                if(value !== null && value !== undefined){
                    value = parseFloat(value.replace(',', '.'));
                }
                if (typeof value !== "number") {
                    return value;
                }
            }
            var formatter = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(value);
        }
      }