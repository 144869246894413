<script setup>
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useProfileStore } from "../stores/mainStore";
  import { formatDate } from "../../composables/formatDate";
  import RBMImage from "./../../components/Utility/RBMImage.vue";
  import { useLabel } from "../../composables/useLabel";

  const profileStore = useProfileStore();

  const routeParams = useRoute().params.id;

  const order = ref();
  const formattedDate = ref({Type: String});

  onMounted(async () => {
      order.value = await profileStore.getOrderById(routeParams);
      formattedDate.value = formatDate(order.value.date);
  });

</script>

<template>

<section class="dashboard-order" aria-labelledby="dashboard-order__header-title" v-if="order">
  <div class="dashboard-order__header" >
    <div class="dashboard-order__return">
      <router-link :to="{ name: 'Bestellingen' }">{{ useLabel().get('ORDER_ALL_ORDERS') }}</router-link>
    </div>

    <h1 id="dashboard-order__header-title">{{ useLabel().get('ORDER_DETAILS_OF_ORDER') }}</h1>

    <div class="dashboard-order__header-specifications" v-if="formattedDate && order.order_number">
        <span class="dashboard-order__header-date" v-if="formattedDate">{{ useLabel().get('ORDER_ORDERED_ON') }}: {{ formattedDate.value }}</span>
        <span class="dashboard-order__header-ordernumber">{{ useLabel().get('ORDER_NUMBER')}}: {{ order.order_number }}</span>
        <span class="dashboard-order__header-track-and-trace-email" v-if="order.email_track_and_trace">{{ useLabel().get('ORDER_TRACK_AND_TRACE_EMAIL') }}: {{ order.email_track_and_trace }}</span>
    </div>
  </div>

  <div class="dashboard-order__products" :class="{ 'dashboard-order__products--download': order.invoice_urls?.url }">
    <div class="dashboard-order__products-header">
      <h2>{{ useLabel().get('ORDER_YOUR_ORDER') }}</h2>
      <a v-if="order.invoice_urls?.url" class="btn-text--smedium--primary-600" :href="order.invoice_urls.url" target="_blank">{{ useLabel().get('ORDER_DOWNLOAD_INVOICE') }}</a>
    </div>

    <div class="dashboard-order__products-items" v-if="order.products">
      <article class="dashboard-order__product" v-for="product in order.products" :key="product.id">
        <div class="dashboard-order__product-content">
          <div class="dashboard-order__product-image" v-if="product.image_object">
            <figure class="asp-base">
              <RBMImage :image="product.image_object"/>
            </figure>
          </div>
          <div class="dashboard-order__product-text">
            <span class="dashboard-order__product-title notranslate">
              <strong>{{ product.quantity }}x&nbsp;</strong>
              {{ product.title }}
            </span>
          </div>
          <div class="dashboard-order__product-price">
            <span>&euro; {{ product.total_price }}</span>
          </div>
        </div>
        <a class="block-link" v-if="product.slug" :href="`/${product.slug}`"></a>
      </article>
    </div>
  </div>

  <div class="dashboard-order__informatiom">
    <div class="dashboard-order__informatiom-header">
      <h2>{{ useLabel().get('ORDER_ADDRESS_AND_PAYMENT_INFORMATION') }}</h2>
    </div>
    <div class="dashboard-order__informatiom-content">
      <div class="dashboard-order__informatiom-addresses">
        <div class="dashboard-order__informatiom-addres" v-if="order.delivery_address">
          <strong>{{ useLabel().get('ORDER_DELIVERY_ADDRESS') }}</strong>
          <p class="notranslate">{{ order.delivery_address.name }}<br />
          {{ order.delivery_address.address }}<br />
          {{ order.delivery_address.postal_code }} {{ order.delivery_address.city }}<br />
          {{ order.delivery_address.country }}</p>
        </div>
        <div class="dashboard-order__informatiom-addres" v-if="order.invoice_address">
          <strong>{{ useLabel().get('ORDER_INVOICE_ADDRESS') }}</strong>
          <p class="notranslate">{{ order.invoice_address.name }}<br />
          {{ order.invoice_address.address }}<br />
          {{ order.invoice_address.postal_code }} {{ order.invoice_address.city }}<br />
          {{ order.invoice_address.country }}</p>
        </div>
      </div>
      <div class="dashboard-order__informatiom-fees">
        <span class="dashboard-order__informatiom-fee" v-if="order.delivery_costs"><strong>{{ useLabel().get('ORDER_DELIVERY') }}</strong><strong>&euro;{{ order.delivery_costs }}</strong></span>
        <span class="dashboard-order__informatiom-fee" v-if="order.total_costs"><strong>{{ useLabel().get('ORDER_TOTAL_PRICE') }}</strong><strong>&euro;{{ order.total_costs }}</strong></span>
        <span class="dashboard-order__informatiom-remark">{{ useLabel().get('ORDER_INCLUDING_VAT') }}</span>
        <div class="dashboard-order__informatiom-invoice" v-if="order.invoice_urls?.url">
          <a class="btn-text--smedium--primary-600" :href="order.invoice_urls.url" target="_blank">{{ useLabel().get('ORDER_DOWNLOAD_INVOICE') }}</a>
        </div>
        <div class="dashboard-order__informatiom-tracking" v-if="order.tracking_url">
          <a :href="order.tracking_url" class="btn-text--smedium--primary-600" target="_blank">
              {{ useLabel().get('ORDER_TRACK_AND_TRACE') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

</template>
