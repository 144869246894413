import { defineStore } from 'pinia';

export const useCategoriesStore = defineStore('categories', {
    state: () => {
        return {
            totalItems: 0,
            items: [],
            categories: [],
            activeCategory: 'all',
            filtersIsLoading: false,
            translations: [],
            allProducts: []
        };
    }, actions: {
        setCategories(object) {
            this.filtersIsLoading = true;

            if ('totalItems' in object && object.totalItems === object.products.length) {
                this.totalItems = object.totalItems;
            } else {
                this.totalItems =  object.products.length;
            }

            this.items = object.products;
            this.allProducts = object.products;
            this.categories = object.categories;
            this.translations = object.translations;
            this.filtersIsLoading = false;
        },
        setItems(items) {
            this.items = items;
            this.totalItems = items.length;
        },
        async updateItemsByCategory(category) {
            if(category === 'all') {
                this.items = this.allProducts;
                this.totalItems = this.allProducts.length;
                this.activeCategory = 'all';
            } else {
                this.items = this.categories[category].products;
                this.activeCategory = category;
                if(this.categories[category].products.length > 0) {
                    this.totalItems =  this.categories[category].products.length;
                } else {
                    this.totalItems =  0;                
                }
            }
        }
    }
});
