const labels = [];

export function useLabel() {

	const get = (key) => {
		return labels[key] ?? key;
	}

	const set = (key, value) => {
		labels[key] = value;
	}

	const setMultiple = (labels = []) => {
		Object.entries(labels).forEach(([key, label]) => {
			set(key, label);
		});
	}

	return {
		get,
		set,
		setMultiple
	}
}