<script setup>
  import { formatDate } from "../../composables/formatDate";
  import RBMImage from "./../../components/Utility/RBMImage.vue";
  import { useLabel } from "../../composables/useLabel";

  const props = defineProps({
    order: Object
  });

  const formattedDate = formatDate(props.order.date);
</script>

<template>
  <article class="dashboard-order-item">
    <div class="dashboard-order-item__content">
      <div class="dashboard-order-item__header">
        <span class="dashboard-order-item__date">{{ useLabel().get('ORDER_ORDERED_ON') }}: {{ formattedDate }}</span>
        <span class="dashboard-order-item__number">{{ useLabel().get('ORDER_NUMBER') }}: {{ order.order_number }}</span>
      </div>
      <div class="dashboard-order-item__main">
        <div class="dashboard-order-item__slider slider slider--dashboard">
          <div class="slider__items">
            <div class="slider__swiper swiper swiper--buttons-sibling swiper--vue">
              <div class="slider__swiper-wrapper swiper-wrapper">
                <article class="dashboard-order-item__product swiper-slide" v-for="product in order.products" :key="product.id">
                  <div class="dashboard-order-item__product-content">
                    <div class="dashboard-order-item__product-image" v-if="product.image_object">
                      <figure class="asp-base">
                        <RBMImage :image="product.image_object"/>
                      </figure>
                    </div>
                    <div class="dashboard-order-item__product-content">
                      <span class="dashboard-order-item__product-text notranslate">
                        <strong>{{ product.quantity }}x</strong>{{ product.title }}
                      </span>
                    </div>
                  </div>

                </article>
              </div>
            </div>
            <div class="slider__buttons swiper__buttons">
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-order-item__footer">
        <router-link
          :to="{name: 'Bestelling', params: { id: order.id }}"
          class="btn-text--smedium--primary-600"
        >
          {{ useLabel().get('ORDER_VIEW_THIS_ORDER') }}
        </router-link>

        <div class="dashboard-order-item__footer--right">
            <a v-if="order.tracking_url !== null" :href="order.tracking_url" class="btn-text--smedium--primary-600" target="_blank">
                {{ useLabel().get('ORDER_TRACK_AND_TRACE') }}
            </a>
            <a v-if="order.invoice_urls?.url" class="btn-text--smedium--primary-600" :href="order.invoice_urls.url" target="_blank">{{ useLabel().get('ORDER_DOWNLOAD_INVOICE') }}</a>
        </div>
      </div>
    </div>
  </article>
</template>
