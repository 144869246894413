<script setup>
  import { onMounted, ref } from "vue";
  import { useFiltersStore } from "../../stores/filters";
  import { useRouter } from "vue-router";
  import { useLabel } from "../../composables/useLabel";
  import { filterActions, filtersResize, showFilter, closeFilter } from '../../services/filterService.js';

  const router = useRouter();
  const currentBase = window.location.origin;

  const showProductItems = (event) => {
    event.target.parentNode.parentNode.classList.toggle('products__filters-content--hidden');
  };

  onMounted(() => {
    filterActions();
    filtersResize();
  });
</script>

<template>
  <div class="products__filters-inner">
    <div class="products__filters-group products__filters-group--categories" v-if="useFiltersStore().mainItem.children.length > 0">
      <div class="products__filters-content" v-if="!useFiltersStore().filtersIsLoading">
        <div class="products__filters-heading">
          <span class="products__filters-title">
            {{ useLabel().get('PRODUCT_CATEGORY') }}
          </span>
        </div>
        <div class="product__filters-items">
          <div class="product__filters-item" v-for="categoryOptions in useFiltersStore().mainItem.children" :key="categoryOptions.id">
            <router-link :to="'/' + categoryOptions.slug">{{ categoryOptions.title }}</router-link>
          </div>
        </div>
      </div>
      <div class="products__filters-content products__filters-content--skeleton" v-else>
        <div class="products__filters-heading">
          <span class="products__filters-title">
            <span class="products__filters-title-inner">
              <!-- -->
            </span>
          </span>
        </div>
        <div class="product__filters-items">
          <div class="product__filters-item" v-for="categoryOptions in useFiltersStore().mainItem.children" :key="categoryOptions.id">
            <!-- -->
          </div>
        </div>
      </div>
    </div>

    <div class="products__filters-group products__filters-group--attributes" v-for="option in useFiltersStore().filters?.options" :key="option.key">
      <div class="products__filters-content" v-if="option.key !== 'category' && !useFiltersStore().filtersIsLoading">
        <div class="products__filters-heading">
          <span class="products__filters-title">
            {{ option.label }}
          </span>
        </div>
        <div
          class="product__filters-items"
          :class="{'products__filters-content--hidden' : Object.keys(option.values).length > 5}"
        >
          <div class="product__filters-item" :class="{'product__filters-item--active' : value.active}" v-for="value in option.values" :key="value.id">
            <a
              :href="value.url.replace(currentBase, '')"
              @click.prevent="{ path: router.push(value.url.replace(currentBase, ''))}"
            >
              {{ value.label }}
            </a>
          </div>
          <div v-if="Object.keys(option.values).length > 5" class="product__filters-toggle btn-text--small--primary-600" @click="showProductItems">
            <span class="product__filters-toggle--open">Meer tonen</span>
            <span class="product__filters-toggle--close">Minder tonen</span>
          </div>
        </div>
      </div>
      <div class="products__filters-content products__filters-content--skeleton" v-else-if="option.key !== 'category' && useFiltersStore().filtersIsLoading">
        <div class="products__filters-heading">
          <span class="products__filters-title">
            <span class="products__filters-title-inner">
              <!-- -->
            </span>
          </span>
        </div>
        <div
          class="product__filters-items"
          :class="{'products__filters-content--hidden' : Object.keys(option.values).length > 5}"
        >
          <div class="product__filters-item" :class="{'product__filters-item--active' : value.active}" v-for="value in option.values" :key="value.id">
            <!-- -->
          </div>
          <div v-if="Object.keys(option.values).length > 5" class="product__filters-toggle" @click="showProductItems">
            <!-- -->
          </div>          
        </div>        
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../sass/elements/skeletons.scss';

.products__filters-inner {
  @apply pr-12;
  @apply md:pr-0;
}

.products__filters-group {
  &:has(+ .products__filters-group) {
      @apply mb-6;
      @apply md:mb-0;
  }
}

.products__filters-title {
  @apply font-fontText font-bold text-16_28_14_22 text-black block border-b border-solid border-border mb-1;
  @apply md:border-b-0 md:px-4;
}

.products__filters-title--trigger {
  @apply cursor-pointer transition-colors duration-300 relative;
  @apply md:pr-9;
  @apply hover:text-primary-600;

  &::after {
    @apply content-["\f106"] font-fontAwesome absolute right-[18px] inset-y-0 flex items-center font-normal;
  }
}

.products__filters-group--categories {
  .product__filters-item {
    a {
        @apply text-16_28_14_22 block py-1;
        @apply md:px-4;
        @apply hover:text-primary-600;
    }
  }
}

.products__filters-group--attributes {
  .products__filters-title {
    @apply mb-3;
    @apply md:h-12 md:flex md:items-center md:mb-0;
  }

  .product__filters-item{
    @apply md:px-4;
    a {
      @apply text-15_20_14_20 text-black flex items-center min-h-[28px] pl-11 relative;
      @apply hover:text-primary-600;

      &::before {
        @apply content-[""] absolute inset-y-0 left-0 my-auto border border-solid border-border h-[28px] w-[28px] rounded font-fontAwesome flex items-center justify-center text-center font-normal;
      }
    }

    &:has(+ .product__filters-item),
    &:has(+ .product__filters-toggle) {
      @apply mb-2;
      @apply md:mb-3;
    }
  }

  .product__filters-item--active {
    a {
      &::before {
        @apply bg-[#FAF2E6] border-primary-500 content-["\f00c"];
      }
    }
  }
}

.products__filters-content {
  @apply md:border-b md:border-solid md:border-border;

  .product__filters-items {
    @apply md:pb-[18px];
  }
}

.products__filters-content--hidden {
  .product__filters-items {
    @apply hidden;
  }

  .products__filters-title--trigger {
    &::after {
      @apply content-["\f107"];
    }
  }
}

.product__filters-toggle {
  @apply md:px-4;
}

.product__filters-items {
  &.products__filters-content--hidden {
    .product__filters-toggle--close{
      @apply hidden;
    }

    .product__filters-item:nth-child(n + 6) {
      @apply opacity-0 h-0 overflow-hidden mb-0;
    }


  }

  &:not(.products__filters-content--hidden) {
    .product__filters-toggle--open {
      @apply hidden;
    }
  }
}

.products__filters-content--skeleton {
  .products__filters-heading {
    @apply md:pt-3;
  }

  .products__filters-title {
    @apply h-[28px] mb-0.5;
  }

  .products__filters-title-inner {
    @apply flex h-[21px] w-24;
    @extend .skeleton;
  }

  .product__filters-items {
    @apply pt-1;
    @apply md:px-4;
  }

  .product__filters-item,
  .product__filters-toggle {
    @apply h-[33px] w-2/3 mb-1 cursor-pointer;
    @extend .skeleton;
  }
}

</style>
