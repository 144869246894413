import {createRouter, createWebHistory} from "vue-router";

import ProfileRoutes from "./profile";
import searchRoutes from "./search";
import shopRoutes from "./shop";

export default createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: { template: '' } },

        ...ProfileRoutes,
        searchRoutes,
        shopRoutes,
    ],
    scrollBehavior(to, from, savedPosition) {
      // always scroll to top
      return {
        top: 0,
        behavior: 'smooth',
      }
    }
});
