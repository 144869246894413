<script setup>
  import { onMounted, ref } from "vue";
  import { useCategoriesStore } from "../../../stores/search";
  import { useRouter } from "vue-router";
  import { useLabel } from "../../../composables/useLabel";
  import { filterActions, filtersResize, showFilter, closeFilter } from '../../../services/filterService.js';

  const router = useRouter();
  const currentBase = window.location.origin;

  const showProductItems = (event) => {
    event.target.parentNode.parentNode.classList.toggle('products__filters-content--hidden');
  };

  const filteredCategory = ref('');

  const filterCategory = (category) => {
    filteredCategory.value = category;
    useCategoriesStore().updateItemsByCategory(category);
    const activeMobileFilter = document.querySelectorAll('.filters__navigation.filters__navigation--active');
    activeMobileFilter.forEach(item => item.classList.remove('filters__navigation--active'));
  };

  onMounted(() => {
    filterActions();
    filtersResize();
  });
</script>

<template>
  <div class="products__filters-inner">
    <div class="products__filters-group products__filters-group--categories" v-if="Object.keys(useCategoriesStore().categories).length > 0">
      <div class="products__filters-content">
        <div class="products__filters-heading">
          <span class="products__filters-title">
            {{ useCategoriesStore().translations.SEARCH_RESULTS_CATEGORIES }}
          </span>
        </div>
        <div class="product__filters-items">
          <div class="product__filters-item" :class="{ 'product__filters-item--active': useCategoriesStore().activeCategory == 'all' }">
            <a @click="filterCategory('all')">{{ useCategoriesStore().translations.SEARCH_RESULTS_ALL_CATEGORIES }}</a>            
          </div>
          <div class="product__filters-item" :class="{ 'product__filters-item--active' : useCategoriesStore().activeCategory == categoryOptions.title }" v-for="categoryOptions in useCategoriesStore().categories" :key="categoryOptions.id">
            <a @click="filterCategory(categoryOptions.title)">{{ categoryOptions.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../../sass/elements/skeletons.scss';

.products__filters-inner {
  @apply pr-12;
  @apply md:pr-0;
}

.products__filters-group {
  &:has(+ .products__filters-group) {
      @apply mb-6;
      @apply md:mb-0;
  }
}

.products__filters-title {
  @apply font-fontText font-bold text-16_28_14_22 text-black block border-b border-solid border-border mb-1;
  @apply md:border-b-0 md:px-4;
}

.products__filters-title--trigger {
  @apply cursor-pointer transition-colors duration-300 relative;
  @apply md:pr-9;
  @apply hover:text-primary-600;

  &::after {
    @apply content-["\f106"] font-fontAwesome absolute right-[18px] inset-y-0 flex items-center font-normal;
  }
}

.products__filters-group--categories {
  .product__filters-item {
    a {
        @apply text-16_28_14_22 block py-1 cursor-pointer;
        @apply md:px-4;
        @apply hover:text-primary-600;
    }
  }
}


.product__filters-item--active {
  a {
    @apply font-bold;
  }
}

.products__filters-content {
  @apply md:border-b md:border-solid md:border-border;

  .product__filters-items {
    @apply md:pb-[18px];
  }
}

.products__filters-content--hidden {
  .product__filters-items {
    @apply hidden;
  }

  .products__filters-title--trigger {
    &::after {
      @apply content-["\f107"];
    }
  }
}

.product__filters-toggle {
  @apply md:px-4;
}

.product__filters-items {
  &.products__filters-content--hidden {
    .product__filters-toggle--close{
      @apply hidden;
    }

    .product__filters-item:nth-child(n + 6) {
      @apply opacity-0 h-0 overflow-hidden mb-0;
    }


  }

  &:not(.products__filters-content--hidden) {
    .product__filters-toggle--open {
      @apply hidden;
    }
  }
}

.products__filters-content--skeleton {
  .products__filters-heading {
    @apply md:pt-3;
  }

  .products__filters-title {
    @apply h-[28px] mb-0.5;
  }

  .products__filters-title-inner {
    @apply flex h-[21px] w-24;
    @extend .skeleton;
  }

  .product__filters-items {
    @apply pt-1;
    @apply md:px-4;
  }

  .product__filters-item,
  .product__filters-toggle {
    @apply h-[33px] w-2/3 mb-1;
    @extend .skeleton;
  }
}

</style>
